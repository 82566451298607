const API_ENDPOINTS = {
  LOGIN: 'api/v2/auth/login/',
  LOGOUT: '/api/v2/auth/logout/',
  REFRESH: '/api/v2/auth/token/refresh/',
  GET_CONTRACTS: (userId: string | number, filterType: string) => `/api/v1/billing/contracts/by-buyer-seller/${userId}/${filterType}`,
  DOWNLOAD_CONTRACT: '/api/v1/billing/generate_html_contract/',
  RESET_PASSWORD: '/api/v2/auth/password/reset',
  RESET_PASSWORD_CONFIRM: '/api/v2/auth/password/reset/confirm',
  GET_CONTRACTS_HOME: 'api/v1/billing/contracts/contracts-home/',
  GET_SHIPPING_ORDERS: 'api/v1/logistic/shipping_orders/contract/',
  POST_NEW_SHIPPING_ORDER: 'api/v1/logistic/shipping_orders/',
  USERS: 'api/v2/core/users/',
  COMPANY_USER: 'api/v2/auth/company-user/',
  COMPANY_VEHICLE_BY_ID: (vehicleId: number) => `/api/v1/logistic/vehicles/${vehicleId}/`,
  COMPANY_VEHICLE: '/api/v1/logistic/vehicles/',
  UPDATE_VEHICLE: (vehicleId: number) => `/api/v1/logistic/vehicles/${vehicleId}`,
  BILLS: '/api/v1/billing/bills/',
  GET_BILLS_BY_CONTRACT: '/api/v1/billing/bills/by-contract/{contractId}/',
  CREATE_BILL: '/api/v1/billing/bills/by-contract/{contractId}/',
  DOWNLOAD_BILL: '/api/v1/billing/bills/{billId}/download/',
  FILTER_BILLS: '/api/v1/billing/bills/filter/',
  MARK_BILL_AS_PAID: '/api/v1/billing/bills/{billId}/mark-paid/',
  GET_CONTRACTS_LOGISTIC: (userId: string, filterType: string) => `/api/v1/billing/contracts/${userId}/${filterType}/`,
  GET_CONTRACTS_BILLS: (userId: string | number, filterType: string) => `/api/v1/billing/contracts/bills/${userId}/${filterType}/`,
  DOWNLOAD_PROOF_PAYMENT: '/api/v1/logistic/shipping_orders/{proofPaymentId}/download-proof-payment/',
  VEHICLE: (companyId: string) => `/api/v1/logistic/vehicles/by-company/${companyId}`,
  CANCEL_SHIPPING_ORDER: '/api/v1/logistic/shipping_orders/{shippingOrderId}/cancel/',
  GET_USER_COMPANIES: (userId: string) => `/api/v2/auth/user/${userId}/companies/`,
  CHANGE_ACTIVE_COMPANY: (userId: string) => `/api/v2/auth/user/mark-as-active/${userId}/`,
  PATCH_CONTRACT_STATUS: (contractId: string | number) => `/api/v1/billing/contracts/${contractId}/`,
}

export default API_ENDPOINTS
