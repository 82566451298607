<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import type { Vehicle } from '@/@core/stores/company'
import { getVehiclesByCompanyId } from '@/@core/stores/company'
import { useVehiclesStore } from '@/@core/stores/vehicle'
import { track } from '@/boot/mixpanel'
import AddNewVehicleDrawer from '@/views/apps/logistics/AddNewVehicleDrawer.vue'
import UpdateVehicleDrawer from '@/views/apps/logistics/UpdateVehicleDrawer.vue'

definePage({
  meta: {
    authenticatedOnly: true,
  },
})

const { t } = useI18n()
const searchQuery = ref('')
const itemsPerPage = ref(10)
const page = ref(1)
const sortBy = ref()
const orderBy = ref()
const vehicleStore = useVehiclesStore()
const alert = ref<{ type: string; message: string } | null>(null)
const alertTimeout = ref<number | null>(null)
const isAddNewVehicleDrawerVisible = ref(false)
const isUpdateVehicleDrawerVisible = ref(false)
const selectedVehicle = ref<Vehicle | null>(null)

const auth = JSON.parse(localStorage.getItem('auth') ?? '')
const companyId = auth?.user?.company?.id

const vehicles = ref<any[]>([])
const totalVehicles = computed(() => vehicles.value.length)

const headers = [
  { title: t('License Plate'), key: 'matricula' },
  { title: t('Name'), key: 'name' },
  { title: t('Actions'), key: 'actions', sortable: false },
]

const showAlert = (type: string, message: string, duration: number = 5000) => {
  alert.value = { type, message }

  if (alertTimeout.value)
    clearTimeout(alertTimeout.value)

  alertTimeout.value = setTimeout(() => {
    alert.value = null
  }, duration) as unknown as number
}

const loadVehicles = async () => {
  if (!companyId)
    return

  try {
    const fetchedVehicles = await getVehiclesByCompanyId(companyId)

    vehicles.value = fetchedVehicles
  }
  catch (error) {
    console.error('Error loading vehicles:', error)
  }
}

const openUpdateVehicleDrawer = (vehicle: Vehicle) => {
  selectedVehicle.value = vehicle
  isUpdateVehicleDrawerVisible.value = true
}

const addNewVehicle = async (vehicleData: Omit<Vehicle, 'id' | 'company'>) => {
  try {
    const fullVehicleData = {
      ...vehicleData,
    }

    await vehicleStore.register_company_vehicle(fullVehicleData)

    await loadVehicles()
    showAlert('success', t('Vehicle added successfully.'))
    track('New company vehicle created')
  }
  catch (error) {
    const errorMessage = t('There was a problem adding the new vehicle. Please try again later.')

    showAlert('error', errorMessage)
    console.error('Error adding new vehicle:', error)
  }
}

const updateVehicle = async (vehicleData: Partial<Vehicle> & { id: number }) => {
  try {
    const fullVehicleData = {
      id: vehicleData.id,
      plate: vehicleData.plate || selectedVehicle.value?.plate || '',
      name: vehicleData.name || '',
    }

    const response = await vehicleStore.update_company_vehicle(fullVehicleData)

    if (response?.status === 500) {
      const errorMessage = t('There was a problem updating the vehicle. Please try again later.')

      showAlert('error', errorMessage)
      console.error('Error updating vehicle:', response)
    }
    else {
      await loadVehicles()
      showAlert('success', t('Vehicle updated successfully.'))
      track('Vehicle updated')
    }
  }
  catch (error: any) {
    const errorMessage = t('There was a problem updating the vehicle. Please try again later.')

    showAlert('error', errorMessage)
    console.error('Error updating vehicle:', error)
  }
}

const updateOptions = (options: any) => {
  sortBy.value = options.sortBy[0]?.key
  orderBy.value = options.sortBy[0]?.order
}

onMounted(() => {
  loadVehicles()
})

onUnmounted(() => {
  if (alertTimeout.value)
    clearTimeout(alertTimeout.value)
})

watch(isAddNewVehicleDrawerVisible, newVal => {
  if (newVal)
    track('Vehicle Add Drawer Opened')
})

watch(isUpdateVehicleDrawerVisible, newVal => {
  if (newVal)
    track('Vehicle Update Drawer Opened')
})
</script>

<template>
  <section>
    <VAlert
      v-if="alert"
      :type="alert.type"
      variant="tonal"
      class="mb-4"
    >
      {{ alert.message }}
    </VAlert>
    <VCard class="mb-6">
      <VCardText class="d-flex flex-wrap gap-4">
        <div class="me-3 d-flex gap-3">
          <AppSelect
            :model-value="itemsPerPage"
            :items="[
              { value: 10, title: '10' },
              { value: 25, title: '25' },
              { value: 50, title: '50' },
              { value: 100, title: '100' },
              { value: -1, title: t('All') },
            ]"
            style="inline-size: 6.25rem;"
            @update:model-value="itemsPerPage = parseInt($event, 10)"
          />
        </div>
        <VSpacer />

        <div class="app-user-search-filter d-flex align-center flex-wrap gap-4">
          <div style="inline-size: 15.625rem;">
            <AppTextField
              v-model="searchQuery"
              :placeholder="t('Vehicles')"
            />
          </div>
          <VBtn
            prepend-icon="tabler-plus"
            @click="isAddNewVehicleDrawerVisible = true"
          >
            {{ t('Add Vehicles') }}
          </VBtn>
        </div>
      </VCardText>

      <VDivider />

      <VDataTableServer
        v-model:items-per-page="itemsPerPage"
        v-model:page="page"
        :items="vehicles"
        :items-length="totalVehicles"
        :headers="headers"
        class="text-no-wrap"
        @update:options="updateOptions"
      >
        <template #item.matricula="{ item }">
          <div>{{ item.plate }}</div>
        </template>

        <template #item.name="{ item }">
          <div class="d-flex align-center gap-x-4">
            <div class="d-flex flex-column">
              <h6 class="text-base">
                <RouterLink
                  :to="{ name: 'apps-user-view-id', params: { id: item.id } }"
                  class="font-weight-medium text-link"
                >
                  {{ item.name }}
                </RouterLink>
              </h6>
            </div>
          </div>
        </template>

        <template #item.actions="{ item }">
          <IconBtn @click="openUpdateVehicleDrawer(item)">
            <VIcon icon="tabler-pencil" />
          </IconBtn>
        </template>

        <template #bottom>
          <TablePagination
            v-model:page="page"
            :items-per-page="itemsPerPage"
            :total-items="totalVehicles"
          />
        </template>
      </VDataTableServer>
    </VCard>

    <AddNewVehicleDrawer
      v-model:isDrawerOpen="isAddNewVehicleDrawerVisible"
      @vehicle-data="addNewVehicle"
    />
    <UpdateVehicleDrawer
      v-model:isDrawerOpen="isUpdateVehicleDrawerVisible"
      :vehicle="selectedVehicle"
      @vehicle-data="updateVehicle"
    />
  </section>
</template>
