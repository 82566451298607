<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useAuthStore } from '@/@core/stores/auth'
import type { Bill } from '@/@core/stores/bill'
import { BillStatus } from '@/@core/stores/bill'
import type { Contract } from '@/@core/stores/contract'
import { ContractStatus, useBillStore, useContractStore } from '@/@core/stores/contract'
import { track } from '@/boot/mixpanel'
import ConfirmationDialog from '@/pages/components/confirmation-dialog.vue'
import BillContractHeader from '@/views/apps/billing/BillContractHeader.vue'
import BillContractTable from '@/views/apps/billing/BillContractTable.vue'

definePage({
  meta: {
    authenticatedOnly: true,
  },
})

const { t } = useI18n()
const contractStore = useContractStore()
const billStore = useBillStore()
const authStore = useAuthStore()
const filter_pending = ContractStatus.PENDING
const filter_paid = ContractStatus.PAID

const filterType = ref(filter_pending)
const contracts = ref<Contract[]>([])
const bills = ref<Bill[]>([])
const isDialogVisible = ref(false)
const selectedStatus = ref<string | null>(null)
const startDate = ref<string | null>(null)
const endDate = ref<string | null>(null)
const modalMessage = ref('')
const dialogAction = ref<() => void>(() => { })
const currentContract = ref<Contract | null>(null)
const alert = ref<{ type: string; message: string } | null>(null)
const alertTimeout = ref<number | null>(null)

const showAlert = (type: string, message: string, duration: number = 5000) => {
  alert.value = { type, message }

  if (alertTimeout.value)
    clearTimeout(alertTimeout.value)

  alertTimeout.value = setTimeout(() => {
    alert.value = null
  }, duration) as unknown as number
}

const isContractClosed = (contract: Contract) => contract.status === ContractStatus.PAID || contract.status === ContractStatus.COMPLETED

watch(filterType, newFilter => {
  if (newFilter === filter_paid)
    contracts.value = contractStore.completedContracts

  else
    contracts.value = contractStore.pendingContracts

  track('Contract filter changed', {
    filter: newFilter,
    user: authStore.user,
  })
})

const statusOptions = [
  { text: t('All'), value: null },
  ...Object.values(BillStatus).map(status => ({ text: t(status), value: status })),
]

onMounted(async () => {
  try {
    await contractStore.fetchAllContractsForBills(authStore.user.id, authStore.access_token)
    contracts.value = contractStore.pendingContracts

    await billStore.fetchAllBills(authStore.user.id, authStore.access_token)
    bills.value = billStore.pendingBills
  }
  catch (error) {
    console.error('Failed to fetch contracts:', error)
  }
})

watch(selectedStatus, () => {
  track('Selected bill status filter')
})

watch(startDate, () => {
  track('Changed Start Due Date filter')
})

watch(endDate, () => {
  track('Changed End Due Date filter')
})

const calculateContractStatus = (contractbills, contract) => {
  const contractClosed = isContractClosed(contract)

  const hasOverdueBills = contractbills.some(bill => bill.status === BillStatus.OVERDUE && !contractClosed)
  const hasPendingBills = contractbills.some(bill => bill.status === BillStatus.PENDING && !contractClosed)
  const allBillsPaid = contractbills.every(bill => bill.status === BillStatus.PAID) || contractClosed

  if (contractbills.length === 0 && !contractClosed)
    return BillStatus.EMPTY

  if (hasOverdueBills)
    return BillStatus.OVERDUE

  if (hasPendingBills)
    return BillStatus.PENDING

  if (allBillsPaid && !contractClosed)
    return BillStatus.PAID

  return null
}

const filterBillsByDate = filteredBills => {
  return filteredBills.filter(bill => {
    const matchesStartDate = startDate.value ? new Date(bill.due_date) >= new Date(startDate.value) : true
    const matchesEndDate = endDate.value ? new Date(bill.due_date) <= new Date(endDate.value) : true

    return matchesStartDate && matchesEndDate
  })
}

const filteredContracts = computed(() => {
  return contracts.value.filter(contract => {
    const { bills: contractBills = [] } = contract

    const filteredBillsByDate = filterBillsByDate(contractBills)

    const contractStatus = calculateContractStatus(contractBills, contract)

    if (selectedStatus.value)
      return contractStatus === selectedStatus.value

    if (!startDate.value && !endDate.value)
      return true

    return filteredBillsByDate.length > 0
  })
})

const sortedContracts = computed(() => {
  const contractsToSort = [...filteredContracts.value]

  const mostRecentContract = contractsToSort.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))[0]

  const overdueContracts = contractsToSort.filter(contract => calculateContractStatus(contract.bills, contract) === BillStatus.OVERDUE && !isContractClosed(contract))
  const pendingContracts = contractsToSort.filter(contract => calculateContractStatus(contract.bills, contract) === BillStatus.PENDING && !isContractClosed(contract))
  const paidContracts = contractsToSort.filter(contract => calculateContractStatus(contract.bills, contract) === BillStatus.PAID || isContractClosed(contract))

  const remainingContracts = contractsToSort.filter(contract => !overdueContracts.includes(contract)
    && !pendingContracts.includes(contract)
    && !paidContracts.includes(contract))

  const orderedContracts = []

  if (mostRecentContract)
    orderedContracts.push(mostRecentContract)

  orderedContracts.push(...overdueContracts)
  orderedContracts.push(...pendingContracts)
  orderedContracts.push(...paidContracts)
  orderedContracts.push(...remainingContracts)

  return Array.from(new Set(orderedContracts.map(contract => contract.id)))
    .map(id => orderedContracts.find(contract => contract.id === id))
})

const updateContractStatus = updatedBill => {
  const contractToUpdate = contracts.value.find(contract => contract.id === updatedBill.contract)

  if (contractToUpdate) {
    const billIndex = contractToUpdate.bills.findIndex(bill => bill.id === updatedBill.id)

    if (billIndex !== -1)
      contractToUpdate.bills[billIndex] = { ...updatedBill }
    else if (!updatedBill.deleted)
      contractToUpdate.bills.push(updatedBill)

    if (updatedBill.deleted)
      contractToUpdate.bills = contractToUpdate.bills.filter(bill => bill.id !== updatedBill.id)

    const newContractStatus = calculateContractStatus(contractToUpdate.bills, contractToUpdate)

    console.log(`Contrato ${contractToUpdate.id} actualizado con estado: ${newContractStatus}`)
  }
}

const showModal = (contract: Contract) => {
  track('Contract Updated Due To Botton', {
    contractId: contract.id,
    user: authStore.user,
  })

  const hasBills = contract.bills && contract.bills.length > 0
  const allBillsPaid = contract.bills.every(bill => bill.status === BillStatus.PAID)

  if (!hasBills)
    modalMessage.value = t('There are no invoices associated with this contract, are you sure you want to set it up as paid?')
  else if (!allBillsPaid)
    modalMessage.value = t('There are still unpaid invoices on this contract, are you sure you want to set it up as paid?')
  else
    modalMessage.value = t('Are you sure you want to establish the contract as paid?')

  currentContract.value = contract

  dialogAction.value = async () => {
    if (currentContract.value) {
      try {
        await contractStore.patchContractStatus(currentContract.value.id.toString())
        currentContract.value.status = ContractStatus.PAID
        isDialogVisible.value = false

        await contractStore.fetchAllContractsForBills(authStore.user.id, authStore.access_token)
        contracts.value = contractStore.pendingContracts

        await billStore.fetchAllBills(authStore.user.id, authStore.access_token)
        bills.value = billStore.pendingBills

        showAlert('success', t('Status of the contract successfully modified.'))
      }
      catch (error) {
        console.error('Error al actualizar el contrato:', error)
      }
    }
  }
  isDialogVisible.value = true
}
</script>

<template>
  <VRow class="match-height">
    <VAlert
      v-if="alert"
      :type="alert.type"
      variant="tonal"
      class="mb-4"
    >
      {{ alert.message }}
    </VAlert>
    <VCol cols="12">
      <VTabs
        v-model="filterType"
        class="my-4"
      >
        <VTab :value="filter_pending">
          {{ t("Pending") }}
        </VTab>
        <VTab :value="filter_paid">
          {{ t("Paids") }}
        </VTab>
      </VTabs>
      <div>
        <div class="filter-container mb-4">
          <VSelect
            v-model="selectedStatus"
            :items="statusOptions"
            item-title="text"
            item-value="value"
            :label="t('Bill Status')"
            dense
            hide-details
          />
          <div class="date-range-filter">
            <VTextField
              v-model="startDate"
              :label="t('Start Due Date')"
              type="date"
              dense
              hide-details
            />
            <VTextField
              v-model="endDate"
              :label="t('End Due Date')"
              type="date"
              dense
              hide-details
            />
          </div>
        </div>
        <div
          v-if="sortedContracts.length === 0"
          class="text-center py-4"
        >
          <p>{{ t('No contracts available') }}</p>
        </div>
        <div
          v-for="contract in sortedContracts"
          v-else
          :key="contract.id"
        >
          <BillContractHeader
            :contract="contract"
            class="contract-header"
          />
          <BillContractTable
            :contract-id="contract.id"
            :bills="contract.bills"
            :is-seller="contract.is_seller"
            :is-buyer="contract.is_buyer"
            :contract-status="contract.value"
            class="contract-table"
            @bill-updated="updateContractStatus"
          />
          <VTooltip bottom>
            <template #activator="{ props: tooltipProps }">
              <div
                v-if="filterType !== filter_paid"
                class="contract-actions"
              >
                <VBtn
                  class="mt-4 checker-button"
                  v-bind="tooltipProps"
                  @click="showModal(contract)"
                >
                  <VIcon
                    size="40"
                    color="white"
                    icon="tabler-check"
                  />
                </VBtn>
              </div>
            </template>
            <span>{{ t('Confirm Contract Payment') }}</span>
          </VTooltip>

          <VDivider class="my-4" />
        </div>
      </div>
    </VCol>
  </VRow>
  <ConfirmationDialog
    v-if="filterType !== filter_paid"
    v-model="isDialogVisible"
    :message="modalMessage"
    :title="t('Establish paid contract')"
    @confirm="dialogAction"
    @cancel="isDialogVisible = false"
  />
</template>

<style scoped>
.filter-container {
  display: flex;
  justify-content: space-between;
  margin-block-end: 20px;
}

.contract-actions {
  display: flex;
  justify-content: flex-end;
  margin-inline-end: 40px;
}

.checker-button {
  background-color: #8fd641 !important;
  inline-size: 68px !important;
  transition: background-color 0.3s ease;
}

.checker-button:hover {
  background-color: green !important;
}

.date-range-filter {
  display: flex;
  gap: 10px;
}

.contract-header,
.contract-table {
  padding: 0;
  margin: 0;
}

.my-4 {
  margin-block: 16px;
  margin-inline: 0;
}
</style>
